body {
    margin: 0;
    padding: 0;
    font-family: 'museo-sans', 'Roboto', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f2ec;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.react-grid-item {
    border: 1px solid #f2f2f2;
    background-color: '#fff';
}

.user-list-item {
    outline: none;
}

.user-list-item:hover {
    cursor: pointer;
    background-color: #fff;
    border-left-color: #4d45bf;
}

